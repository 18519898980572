<template lang="">
  <section id="course-part" class="pt-115 pb-120 gray-bg">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-title pb-45">
            <h5>Our course</h5>
            <h2>Featured courses</h2>
          </div>
          <!-- section title -->
        </div>
      </div>
      <!-- row -->
      <div class="row course-slied mt-30" ref="coursePart">
        <div class="col-lg-4">
          <div class="singel-course">
            <div class="thum">
              <div class="image">
                <img src="assets/images/course/cu-1.jpg" alt="Course" />
              </div>
              <div class="price">
                <span>Free</span>
              </div>
            </div>
            <div class="cont">
              <ul>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
              </ul>
              <span>(20 Reviws)</span>
              <a href="courses-singel.html">
                <h4>Learn basis javascirpt from start for beginner</h4>
              </a>
              <div class="course-teacher">
                <div class="thum">
                  <a href="#"><img src="assets/images/course/teacher/t-1.jpg" alt="teacher" /></a>
                </div>
                <div class="name">
                  <a href="#">
                    <h6>Mark anthem</h6>
                  </a>
                </div>
                <div class="admin">
                  <ul>
                    <li>
                      <a href="#"><i class="fa fa-user"></i><span>31</span></a>
                    </li>
                    <li>
                      <a href="#"><i class="fa fa-heart"></i><span>10</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- singel course -->
        </div>
        <div class="col-lg-4">
          <div class="singel-course">
            <div class="thum">
              <div class="image">
                <img src="assets/images/course/cu-2.jpg" alt="Course" />
              </div>
              <div class="price">
                <span>Free</span>
              </div>
            </div>
            <div class="cont">
              <ul>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
              </ul>
              <span>(20 Reviws)</span>
              <a href="courses-singel.html">
                <h4>Learn basis javascirpt from start for beginner</h4>
              </a>
              <div class="course-teacher">
                <div class="thum">
                  <a href="#"><img src="assets/images/course/teacher/t-2.jpg" alt="teacher" /></a>
                </div>
                <div class="name">
                  <a href="#">
                    <h6>Mark anthem</h6>
                  </a>
                </div>
                <div class="admin">
                  <ul>
                    <li>
                      <a href="#"><i class="fa fa-user"></i><span>31</span></a>
                    </li>
                    <li>
                      <a href="#"><i class="fa fa-heart"></i><span>10</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- singel course -->
        </div>
        <div class="col-lg-4">
          <div class="singel-course">
            <div class="thum">
              <div class="image">
                <img src="assets/images/course/cu-3.jpg" alt="Course" />
              </div>
              <div class="price">
                <span>Free</span>
              </div>
            </div>
            <div class="cont">
              <ul>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
              </ul>
              <span>(20 Reviws)</span>
              <a href="courses-singel.html">
                <h4>Learn basis javascirpt from start for beginner</h4>
              </a>
              <div class="course-teacher">
                <div class="thum">
                  <a href="#"><img src="assets/images/course/teacher/t-3.jpg" alt="teacher" /></a>
                </div>
                <div class="name">
                  <a href="#">
                    <h6>Mark anthem</h6>
                  </a>
                </div>
                <div class="admin">
                  <ul>
                    <li>
                      <a href="#"><i class="fa fa-user"></i><span>31</span></a>
                    </li>
                    <li>
                      <a href="#"><i class="fa fa-heart"></i><span>10</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- singel course -->
        </div>
        <div class="col-lg-4">
          <div class="singel-course">
            <div class="thum">
              <div class="image">
                <img src="assets/images/course/cu-4.jpg" alt="Course" />
              </div>
              <div class="price">
                <span>Free</span>
              </div>
            </div>
            <div class="cont">
              <ul>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
              </ul>
              <span>(20 Reviws)</span>
              <a href="courses-singel.html">
                <h4>Learn basis javascirpt from start for beginner</h4>
              </a>
              <div class="course-teacher">
                <div class="thum">
                  <a href="#"><img src="assets/images/course/teacher/t-4.jpg" alt="teacher" /></a>
                </div>
                <div class="name">
                  <a href="#">
                    <h6>Mark anthem</h6>
                  </a>
                </div>
                <div class="admin">
                  <ul>
                    <li>
                      <a href="#"><i class="fa fa-user"></i><span>31</span></a>
                    </li>
                    <li>
                      <a href="#"><i class="fa fa-heart"></i><span>10</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- singel course -->
        </div>
        <div class="col-lg-4">
          <div class="singel-course">
            <div class="thum">
              <div class="image">
                <img src="assets/images/course/cu-5.jpg" alt="Course" />
              </div>
              <div class="price">
                <span>Free</span>
              </div>
            </div>
            <div class="cont">
              <ul>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
              </ul>
              <span>(20 Reviws)</span>
              <a href="courses-singel.html">
                <h4>Learn basis javascirpt from start for beginner</h4>
              </a>
              <div class="course-teacher">
                <div class="thum">
                  <a href="#"><img src="assets/images/course/teacher/t-5.jpg" alt="teacher" /></a>
                </div>
                <div class="name">
                  <a href="#">
                    <h6>Mark anthem</h6>
                  </a>
                </div>
                <div class="admin">
                  <ul>
                    <li>
                      <a href="#"><i class="fa fa-user"></i><span>31</span></a>
                    </li>
                    <li>
                      <a href="#"><i class="fa fa-heart"></i><span>10</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- singel course -->
        </div>
      </div>
      <!-- course slied -->
    </div>
    <!-- container -->
  </section>
</template>
<script>
  import { onMounted, ref } from 'vue';
  import $ from 'jquery'; // Import jQuery
  import 'slick-carousel'; // Import Slick Carousel JS
  import 'slick-carousel/slick/slick.css'; // Import Slick Carousel CSS

  export default {
    name: 'CoursesPart',
    setup() {
      const coursePart = ref(null);

      onMounted(() => {
        $(coursePart.value).slick({
          dots: false,
          infinite: true,
          speed: 800,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 5000,
          arrows: true,
          prevArrow: '<span class="prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow: '<span class="next"><i class="fa fa-angle-right"></i></span>',
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
              }
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });
      });
      return {
        coursePart
      }
    }
  }
</script>
