<template>
  <Header />
  <section
    id="page-banner"
    class="pt-105 pb-110 bg_cover"
    data-overlay="8"
    style="background-image: url(assets/images/page-banner-1.jpg)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="page-banner-cont">
            <h2>About Us</h2>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ol>
            </nav>
          </div>
          <!-- page banner cont -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>

  <section id="about-page" class="pt-70 pb-110">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="section-title mt-50">
            <h5>About us</h5>
            <h2>Welcome to Edubin</h2>
          </div>
          <!-- section title -->
          <div class="about-cont">
            <p>
              Lorem ipsum gravida nibh vel velit auctor aliquetn sollicitudirem
              quibibendum auci elit cons equat ipsutis sem nibh id elit. Duis
              sed odio sit amet nibh vulputate cursus a sit amet . Morbi
              accumsan ipsum velit. Nam nec tellus a odio tincidunt mauris.
              <br />
              <br />
              auci elit cons equat ipsutis sem nibh id elit. Duis sed odio sit
              amet nibh vulputate cursus a sit amet . Morbi accumsan ipsum
              velit. Nam nec tellus a odio tincidunt mauris
            </p>
          </div>
        </div>
        <!-- about cont -->
        <div class="col-lg-7">
          <div class="about-image mt-50">
            <img src="assets/images/about/about-2.jpg" alt="About" />
          </div>
          <!-- about imag -->
        </div>
      </div>
      <!-- row -->
      <div class="about-items pt-60">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 col-sm-10">
            <div class="about-singel-items mt-30">
              <span>01</span>
              <h4>Why Choose us</h4>
              <p>
                Lorem ipsum gravida nibh vel velit auctor aliquetn
                sollicitudirem quibibendum auci elit sollicitudirem quibibendum
                auci
              </p>
            </div>
            <!-- about singel -->
          </div>
          <div class="col-lg-4 col-md-6 col-sm-10">
            <div class="about-singel-items mt-30">
              <span>02</span>
              <h4>Our Mission</h4>
              <p>
                Lorem ipsum gravida nibh vel velit auctor aliquetn
                sollicitudirem quibibendum auci elit sollicitudirem quibibendum
                auci
              </p>
            </div>
            <!-- about singel -->
          </div>
          <div class="col-lg-4 col-md-6 col-sm-10">
            <div class="about-singel-items mt-30">
              <span>03</span>
              <h4>Our vission</h4>
              <p>
                Lorem ipsum gravida nibh vel velit auctor aliquetn
                sollicitudirem quibibendum auci elit sollicitudirem quibibendum
                auci
              </p>
            </div>
            <!-- about singel -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- about items -->
    </div>
    <!-- container -->
  </section>

  <div
    id="counter-part"
    class="bg_cover pt-65 pb-110"
    data-overlay="8"
    style="background-image: url(assets/images/bg-2.jpg)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <div class="singel-counter text-center mt-40">
            <span><span class="counter">30,000</span>+</span>
            <p>Students enrolled</p>
          </div>
          <!-- singel counter -->
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="singel-counter text-center mt-40">
            <span><span class="counter">41,000</span>+</span>
            <p>Courses Uploaded</p>
          </div>
          <!-- singel counter -->
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="singel-counter text-center mt-40">
            <span><span class="counter">11,000</span>+</span>
            <p>People certifie</p>
          </div>
          <!-- singel counter -->
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="singel-counter text-center mt-40">
            <span><span class="counter">39,000</span>+</span>
            <p>Global Teachers</p>
          </div>
          <!-- singel counter -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </div>

  <section id="teachers-part" class="pt-65 pb-120">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="section-title mt-50 pb-35">
            <h5>Featured Teachers</h5>
            <h2>Meet Our teachers</h2>
          </div>
          <!-- section title -->
        </div>
      </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <div class="singel-teachers mt-30 text-center">
            <div class="image">
              <img src="assets/images/teachers/t-1.jpg" alt="Teachers" />
            </div>
            <div class="cont">
              <a href="teachers-singel.html">
                <h6>Mark alen</h6>
              </a>
              <span>Vice chencelor</span>
            </div>
          </div>
          <!-- singel teachers -->
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="singel-teachers mt-30 text-center">
            <div class="image">
              <img src="assets/images/teachers/t-2.jpg" alt="Teachers" />
            </div>
            <div class="cont">
              <a href="teachers-singel.html">
                <h6>Mark alen</h6>
              </a>
              <span>Vice chencelor</span>
            </div>
          </div>
          <!-- singel teachers -->
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="singel-teachers mt-30 text-center">
            <div class="image">
              <img src="assets/images/teachers/t-3.jpg" alt="Teachers" />
            </div>
            <div class="cont">
              <a href="teachers-singel.html">
                <h6>Mark alen</h6>
              </a>
              <span>Vice chencelor</span>
            </div>
          </div>
          <!-- singel teachers -->
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="singel-teachers mt-30 text-center">
            <div class="image">
              <img src="assets/images/teachers/t-4.jpg" alt="Teachers" />
            </div>
            <div class="cont">
              <a href="teachers-singel.html">
                <h6>Mark alen</h6>
              </a>
              <span>Vice chencelor</span>
            </div>
          </div>
          <!-- singel teachers -->
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="singel-teachers mt-30 text-center">
            <div class="image">
              <img src="assets/images/teachers/t-5.jpg" alt="Teachers" />
            </div>
            <div class="cont">
              <a href="teachers-singel.html">
                <h6>Mark alen</h6>
              </a>
              <span>Vice chencelor</span>
            </div>
          </div>
          <!-- singel teachers -->
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="singel-teachers mt-30 text-center">
            <div class="image">
              <img src="assets/images/teachers/t-6.jpg" alt="Teachers" />
            </div>
            <div class="cont">
              <a href="teachers-singel.html">
                <h6>Mark alen</h6>
              </a>
              <span>Vice chencelor</span>
            </div>
          </div>
          <!-- singel teachers -->
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="singel-teachers mt-30 text-center">
            <div class="image">
              <img src="assets/images/teachers/t-7.jpg" alt="Teachers" />
            </div>
            <div class="cont">
              <a href="teachers-singel.html">
                <h6>Mark alen</h6>
              </a>
              <span>Vice chencelor</span>
            </div>
          </div>
          <!-- singel teachers -->
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="singel-teachers mt-30 text-center">
            <div class="image">
              <img src="assets/images/teachers/t-8.jpg" alt="Teachers" />
            </div>
            <div class="cont">
              <a href="teachers-singel.html">
                <h6>Mark alen</h6>
              </a>
              <span>Vice chencelor</span>
            </div>
          </div>
          <!-- singel teachers -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>
  <TestimonialPart />
  <PartnerComponent />
  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import PartnerComponent from "@/components/PartnerComponent.vue";
import TestimonialPart from "@/components/TestimonialPart.vue";

export default {
  components: {
    Header,
    Footer,
    PartnerComponent,
    TestimonialPart,
  },
};
</script>