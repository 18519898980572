<template>
  <Header />
  <HeroSlider />
  <CategoryPart />

  <section id="about-part" class="pt-65">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="section-title mt-50">
            <h5>About us</h5>
            <h2>Welcome to Edubin</h2>
          </div>
          <!-- section title -->
          <div class="about-cont">
            <p>
              Lorem ipsum gravida nibh vel velit auctor aliquetn sollicitudirem
              quibibendum auci elit cons equat ipsutis sem nibh id elit. Duis
              sed odio sit amet nibh vulputate cursus a sit amet . Morbi
              accumsan ipsum velit. Nam nec tellus a odio tincidunt mauris.
              <br />
              <br />
              auci elit cons equat ipsutis sem nibh id elit. Duis sed odio sit
              amet nibh vulputate cursus a sit amet . Morbi accumsan ipsum
              velit. Nam nec tellus a odio tincidunt mauris
            </p>
            <a href="#" class="main-btn mt-55">Learn More</a>
          </div>
        </div>
        <!-- about cont -->
        <div class="col-lg-6 offset-lg-1">
          <div class="about-event mt-50">
            <div class="event-title">
              <h3>Upcoming events</h3>
            </div>
            <!-- event title -->
            <ul>
              <li>
                <div class="singel-event">
                  <span><i class="fa fa-calendar"></i> 2 December 2018</span>
                  <a href="events-singel.html">
                    <h4>Campus clean workshop</h4>
                  </a>
                  <span><i class="fa fa-clock-o"></i> 10:00 Am - 3:00 Pm</span>
                  <span><i class="fa fa-map-marker"></i> Rc Auditorim</span>
                </div>
              </li>
              <li>
                <div class="singel-event">
                  <span><i class="fa fa-calendar"></i> 2 December 2018</span>
                  <a href="events-singel.html">
                    <h4>Tech Summit</h4>
                  </a>
                  <span><i class="fa fa-clock-o"></i> 10:00 Am - 3:00 Pm</span>
                  <span><i class="fa fa-map-marker"></i> Rc Auditorim</span>
                </div>
              </li>
              <li>
                <div class="singel-event">
                  <span><i class="fa fa-calendar"></i> 2 December 2018</span>
                  <a href="events-singel.html">
                    <h4>Enviroement conference</h4>
                  </a>
                  <span><i class="fa fa-clock-o"></i> 10:00 Am - 3:00 Pm</span>
                  <span><i class="fa fa-map-marker"></i> Rc Auditorim</span>
                </div>
              </li>
            </ul>
          </div>
          <!-- about event -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
    <div class="about-bg">
      <img src="assets/images/about/bg-1.png" alt="About" />
    </div>
  </section>

  <section id="apply-aprt" class="pb-120">
    <div class="container">
      <div class="apply">
        <div class="row no-gutters">
          <div class="col-lg-6">
            <div class="apply-cont apply-color-1">
              <h3>Apply for fall 2019</h3>
              <p>
                Gravida nibh vel velit auctor aliquetn sollicitudirem sem
                quibibendum auci elit cons equat ipsutis sem nibh id elituis sed
                odio sit amet nibh vulputate cursus equat ipsutis.
              </p>
              <a href="#" class="main-btn">Apply Now</a>
            </div>
            <!-- apply cont -->
          </div>
          <div class="col-lg-6">
            <div class="apply-cont apply-color-2">
              <h3>Apply for scholarship</h3>
              <p>
                Gravida nibh vel velit auctor aliquetn sollicitudirem sem
                quibibendum auci elit cons equat ipsutis sem nibh id elituis sed
                odio sit amet nibh vulputate cursus equat ipsutis.
              </p>
              <a href="#" class="main-btn">Apply Now</a>
            </div>
            <!-- apply cont -->
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>

  <CoursesPart />

  <section
    id="video-feature"
    class="bg_cover pt-60 pb-110"
    style="background-image: url(assets/images/bg-1.jpg)"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 order-last order-lg-first">
          <div class="video text-lg-left text-center pt-50">
            <a
              class="Video-popup"
              href="https://www.youtube.com/watch?v=bRRtdzJH1oE"
              ><i class="fa fa-play"></i
            ></a>
          </div>
          <!-- row -->
        </div>
        <div class="col-lg-5 offset-lg-1 order-first order-lg-last">
          <div class="feature pt-50">
            <div class="feature-title">
              <h3>Our Facilities</h3>
            </div>
            <ul>
              <li>
                <div class="singel-feature">
                  <div class="icon">
                    <img src="assets/images/all-icon/f-1.png" alt="icon" />
                  </div>
                  <div class="cont">
                    <h4>Global Certificate</h4>
                    <p>
                      Gravida nibh vel velit auctor aliquetn auci elit cons
                      solliazcitudirem sem quibibendum sem nibhutis.
                    </p>
                  </div>
                </div>
                <!-- singel feature -->
              </li>
              <li>
                <div class="singel-feature">
                  <div class="icon">
                    <img src="assets/images/all-icon/f-2.png" alt="icon" />
                  </div>
                  <div class="cont">
                    <h4>Alumni Support</h4>
                    <p>
                      Gravida nibh vel velit auctor aliquetn auci elit cons
                      solliazcitudirem sem quibibendum sem nibhutis.
                    </p>
                  </div>
                </div>
                <!-- singel feature -->
              </li>
              <li>
                <div class="singel-feature">
                  <div class="icon">
                    <img src="assets/images/all-icon/f-3.png" alt="icon" />
                  </div>
                  <div class="cont">
                    <h4>Books & Library</h4>
                    <p>
                      Gravida nibh vel velit auctor aliquetn auci elit cons
                      solliazcitudirem sem quibibendum sem nibhutis.
                    </p>
                  </div>
                </div>
                <!-- singel feature -->
              </li>
            </ul>
          </div>
          <!-- feature -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
    <div class="feature-bg"></div>
    <!-- feature bg -->
  </section>

  <section id="teachers-part" class="pt-70 pb-120">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="section-title mt-50">
            <h5>Featured Teachers</h5>
            <h2>Meet Our teachers</h2>
          </div>
          <!-- section title -->
          <div class="teachers-cont">
            <p>
              Lorem ipsum gravida nibh vel velit auctor aliquetn sollicitudirem
              quibibendum auci elit cons equat ipsutis sem nibh id elit. Duis
              sed odio sit amet nibh vulputate cursus a sit amet . Morbi
              accumsan ipsum velit. Nam nec tellus a odio tincidunt mauris.
              <br />
              <br />
              auci elit cons equat ipsutis sem nibh id elit. Duis sed odio sit
              amet nibh vulputate cursus a sit amet . Morbi accumsan ipsum
              velit. Nam nec tellus a odio tincidunt mauris
            </p>
            <a href="#" class="main-btn mt-55">Career with us</a>
          </div>
          <!-- teachers cont -->
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="teachers mt-20">
            <div class="row">
              <div class="col-sm-6">
                <div class="singel-teachers mt-30 text-center">
                  <div class="image">
                    <img src="assets/images/teachers/t-1.jpg" alt="Teachers" />
                  </div>
                  <div class="cont">
                    <a href="teachers-singel.html">
                      <h6>Mark alen</h6>
                    </a>
                    <span>Vice chencelor</span>
                  </div>
                </div>
                <!-- singel teachers -->
              </div>
              <div class="col-sm-6">
                <div class="singel-teachers mt-30 text-center">
                  <div class="image">
                    <img src="assets/images/teachers/t-2.jpg" alt="Teachers" />
                  </div>
                  <div class="cont">
                    <a href="teachers-singel.html">
                      <h6>David card</h6>
                    </a>
                    <span>Pro chencelor</span>
                  </div>
                </div>
                <!-- singel teachers -->
              </div>
              <div class="col-sm-6">
                <div class="singel-teachers mt-30 text-center">
                  <div class="image">
                    <img src="assets/images/teachers/t-3.jpg" alt="Teachers" />
                  </div>
                  <div class="cont">
                    <a href="teachers-singel.html">
                      <h6>Rebeka alig</h6>
                    </a>
                    <span>Pro chencelor</span>
                  </div>
                </div>
                <!-- singel teachers -->
              </div>
              <div class="col-sm-6">
                <div class="singel-teachers mt-30 text-center">
                  <div class="image">
                    <img src="assets/images/teachers/t-4.jpg" alt="Teachers" />
                  </div>
                  <div class="cont">
                    <a href="teachers-singel.html">
                      <h6>Hanna bein</h6>
                    </a>
                    <span>Aerobics head</span>
                  </div>
                </div>
                <!-- singel teachers -->
              </div>
            </div>
            <!-- row -->
          </div>
          <!-- teachers -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>

  <section id="publication-part" class="pt-115 pb-120 gray-bg">
    <div class="container">
      <div class="row align-items-end">
        <div class="col-lg-6 col-md-8 col-sm-7">
          <div class="section-title pb-60">
            <h5>Publications</h5>
            <h2>From Store</h2>
          </div>
          <!-- section title -->
        </div>
        <div class="col-lg-6 col-md-4 col-sm-5">
          <div class="products-btn text-right pb-60">
            <a href="#" class="main-btn">All Products</a>
          </div>
          <!-- products btn -->
        </div>
      </div>
      <!-- row -->
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6 col-sm-8">
          <div class="singel-publication mt-30">
            <div class="image">
              <img src="assets/images/publication/p-1.jpg" alt="Publication" />
              <div class="add-cart">
                <ul>
                  <li>
                    <a href="#"><i class="fa fa-shopping-cart"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-heart-o"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="cont">
              <div class="name">
                <a href="shop-singel.html">
                  <h6>Set for life</h6>
                </a>
                <span>By Scott Trench</span>
              </div>
              <div class="button text-right">
                <a href="#" class="main-btn">Buy Now ($50)</a>
              </div>
            </div>
          </div>
          <!-- singel publication -->
        </div>
        <div class="col-lg-3 col-md-6 col-sm-8">
          <div class="singel-publication mt-30">
            <div class="image">
              <img src="assets/images/publication/p-2.jpg" alt="Publication" />
              <div class="add-cart">
                <ul>
                  <li>
                    <a href="#"><i class="fa fa-shopping-cart"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-heart-o"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="cont">
              <div class="name">
                <a href="shop-singel.html">
                  <h6>A Daughters</h6>
                </a>
                <span>By Scott Trench</span>
              </div>
              <div class="button text-right">
                <a href="#" class="main-btn">Buy Now ($30)</a>
              </div>
            </div>
          </div>
          <!-- singel publication -->
        </div>
        <div class="col-lg-3 col-md-6 col-sm-8">
          <div class="singel-publication mt-30">
            <div class="image">
              <img src="assets/images/publication/p-3.jpg" alt="Publication" />
              <div class="add-cart">
                <ul>
                  <li>
                    <a href="#"><i class="fa fa-shopping-cart"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-heart-o"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="cont">
              <div class="name">
                <a href="shop-singel.html">
                  <h6>A Magnet</h6>
                </a>
                <span>By Scott Trench</span>
              </div>
              <div class="button text-right">
                <a href="#" class="main-btn">Buy Now ($20)</a>
              </div>
            </div>
          </div>
          <!-- singel publication -->
        </div>
        <div class="col-lg-3 col-md-6 col-sm-8">
          <div class="singel-publication mt-30">
            <div class="image">
              <img src="assets/images/publication/p-4.jpg" alt="Publication" />
              <div class="add-cart">
                <ul>
                  <li>
                    <a href="#"><i class="fa fa-shopping-cart"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-heart-o"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="cont">
              <div class="name">
                <a href="shop-singel.html">
                  <h6>Pices of light</h6>
                </a>
                <span>By Scott Trench</span>
              </div>
              <div class="button text-right">
                <a href="#" class="main-btn">Buy Now ($75)</a>
              </div>
            </div>
          </div>
          <!-- singel publication -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>
  <!-- testimonial -->
  <TestimonialPart />

  <section id="news-part" class="pt-115 pb-110">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-title pb-50">
            <h5>Latest News</h5>
            <h2>From the news</h2>
          </div>
          <!-- section title -->
        </div>
      </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-6">
          <div class="singel-news mt-30">
            <div class="news-thum pb-25">
              <img src="assets/images/news/n-1.jpg" alt="News" />
            </div>
            <div class="news-cont">
              <ul>
                <li>
                  <a href="#"><i class="fa fa-calendar"></i>2 December 2018 </a>
                </li>
                <li>
                  <a href="#"> <span>By</span> Adam linn</a>
                </li>
              </ul>
              <a href="blog-singel.html">
                <h3>Tips to grade high cgpa in university life</h3>
              </a>
              <p>
                Lorem ipsum gravida nibh vel velit auctor aliquetn
                sollicitudirem quibibendum auci elit cons equat ipsutis sem nibh
                id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet
                mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio
                tincidunt .
              </p>
            </div>
          </div>
          <!-- singel news -->
        </div>
        <div class="col-lg-6">
          <div class="singel-news news-list">
            <div class="row">
              <div class="col-sm-4">
                <div class="news-thum mt-30">
                  <img src="assets/images/news/ns-1.jpg" alt="News" />
                </div>
              </div>
              <div class="col-sm-8">
                <div class="news-cont mt-30">
                  <ul>
                    <li>
                      <a href="#"
                        ><i class="fa fa-calendar"></i>2 December 2018
                      </a>
                    </li>
                    <li>
                      <a href="#"> <span>By</span> Adam linn</a>
                    </li>
                  </ul>
                  <a href="blog-singel.html">
                    <h3>Intellectual communication</h3>
                  </a>
                  <p>
                    Gravida nibh vel velit auctor aliquetn sollicitudirem
                    quibibendum auci elit cons vel.
                  </p>
                </div>
              </div>
            </div>
            <!-- row -->
          </div>
          <!-- singel news -->
          <div class="singel-news news-list">
            <div class="row">
              <div class="col-sm-4">
                <div class="news-thum mt-30">
                  <img src="assets/images/news/ns-2.jpg" alt="News" />
                </div>
              </div>
              <div class="col-sm-8">
                <div class="news-cont mt-30">
                  <ul>
                    <li>
                      <a href="#"
                        ><i class="fa fa-calendar"></i>2 December 2018
                      </a>
                    </li>
                    <li>
                      <a href="#"> <span>By</span> Adam linn</a>
                    </li>
                  </ul>
                  <a href="blog-singel.html">
                    <h3>Study makes you perfect</h3>
                  </a>
                  <p>
                    Gravida nibh vel velit auctor aliquetn sollicitudirem
                    quibibendum auci elit cons vel.
                  </p>
                </div>
              </div>
            </div>
            <!-- row -->
          </div>
          <!-- singel news -->
          <div class="singel-news news-list">
            <div class="row">
              <div class="col-sm-4">
                <div class="news-thum mt-30">
                  <img src="assets/images/news/ns-3.jpg" alt="News" />
                </div>
              </div>
              <div class="col-sm-8">
                <div class="news-cont mt-30">
                  <ul>
                    <li>
                      <a href="#"
                        ><i class="fa fa-calendar"></i>2 December 2018
                      </a>
                    </li>
                    <li>
                      <a href="#"> <span>By</span> Adam linn</a>
                    </li>
                  </ul>
                  <a href="blog-singel.html">
                    <h3>Technology edcution is now....</h3>
                  </a>
                  <p>
                    Gravida nibh vel velit auctor aliquetn sollicitudirem
                    quibibendum auci elit cons vel.
                  </p>
                </div>
              </div>
            </div>
            <!-- row -->
          </div>
          <!-- singel news -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>
  <PartnerComponent />
  <Footer />
</template>

<script>
import CategoryPart from "@/components/CategoryPart.vue";
import CoursesPart from "@/components/CoursesPart.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import HeroSlider from "@/components/HeroSlider.vue";
import PartnerComponent from "@/components/PartnerComponent.vue";
import TestimonialPart from "@/components/TestimonialPart.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
    PartnerComponent,
    HeroSlider,
    CategoryPart,
    TestimonialPart,
    CoursesPart,
  },
};
</script>